export default class Products {
    constructor(
        name,
        description,
        price,
        id_category,
        id_entity,
        has_taxes,
        taxes

    ) {
        this.name = name ? name : '',
        this.description = description ? description : '',
        this.price = price? price : '',
        this.id_category = id_category ? id_category : '',
        this.id_entity = id_entity ? id_entity : '',
        this.has_taxes = has_taxes ? has_taxes : false,
        this.taxes = taxes ? taxes : ''
    }
}