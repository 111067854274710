export default class Topping {
    constructor(
        name,
        code_type_product,
        id_product,
        description,
        required,
        quantity
    ) {
        this.name = name ? name : '',
        this.code_type_product = code_type_product ? code_type_product : '',
        this.id_product = id_product ? id_product : '',
        this.description = description ? description : '',
        this.required = required ? required : false,
        this.quantity =quantity ? quantity : 0
    }
}