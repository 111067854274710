<template>
  <div  class="sm:px-5 md:px-10 py-5 min-h-screen">
       <v-loading :is_loading="isloading" />
      <div>
          <div class="grid md:flex justify-between items-center">
               <div class="flex justify-end md:hidden">
                   <div >
                        <v-back-btn/>
                   </div>
                </div>
                <div class="grid gap-y-3">
                        <div class="mt-2 grid gap-y-1">
                        <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl sm:text-center md:text-left ">{{'Información del Menú'}}</label>
                            <span class="font-sans text-gray-400 text-base md:text-lg tracking-wide  sm:text-center md:text-left">Visualiza, administra, crea complementos y variantes</span>
                        </div>
                </div>
                <div class="hidden md:block">
                        <v-back-btn/>
                </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-12 h-auto gap-5 gap-y-5 ">
              <div class="col-start-1 col-end-1 md:col-start-1 md:col-end-10">
                   <div class="block gap-y-3 w-full">
                         <div class="w-full">
                            <v-menu-info :menu="menu" />
                       </div>
                        <div class="mt-5">
                        <v-card elevation="1" class="px-5 py-5">
                             <v-tabs  
                             v-model="tab"
                             next-icon="mdi-chevron-right"
                             prev-icon="mdi-chevron-left"
                             :show-arrows="true"
                             >
                                <v-tabs-slider color="blue darken-2"></v-tabs-slider>
                                <v-tab>COMPLEMENTOS</v-tab>
                                <v-tab>VARIANTES</v-tab>
                                
                            </v-tabs>
                             <v-tabs-items v-model="tab">
                                 <v-tab-item>
                                     <v-menu-more-topping :id_menu="id" v-if="tab==0" />
                                 </v-tab-item>
                                 <v-tab-item>
                                     <v-menu-more-variant :id_menu="id" v-if="tab==1" />
                                 </v-tab-item>
                             </v-tabs-items>
                       </v-card>
                        </div>
                   </div>
              </div>
               <div class="col-start-1 col-end-1 md:col-start-10 md:col-end-13">
                  <v-menu-more-info />
               </div>
          </div>
      </div>
  </div>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import { MenuService } from '../../../../services/HSuite/Context/hsuite-product-context';
import menu from '../../../../models/products/products.model';
import vmenuinfo from './components/vmenu.info.vue';
import vmenumoreinfo from './components/vmenumoreinfo.vue';
import vmenutopping from './components/vmenutopping.vue';
import vmenuvariants from './components/vmenuvariants.vue';

export default {
    mixins: [toastMixin],
    props: {
        id: {
            type: String,
            default: ""
        },
        identity: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-menu-info': vmenuinfo,
        'v-menu-more-info': vmenumoreinfo,
        'v-menu-more-topping': vmenutopping,
        'v-menu-more-variant': vmenuvariants
    },
    mounted() {
        this.init();
    },
    data: function() {
        return {
            isloading: false,
            tab: null,
            menu: new menu()
        }
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getMenu(this.id);
            this.isloading = false;
        },
        getMenu: async function(id) {
            let response = await MenuService.getById(id);
            if(response.success) {
                this.menu = response.data;
            }else {
                this.failure(response.message);
            }
        }
    }

}
</script>

<style>

</style>