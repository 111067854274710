<template>
     <v-card class="px-5 py-3" elevation="1">
        <div class="flex justify-between items-center">
            <label class="font-sans font-bold tracking-wide text-gray-700 text-lg sm:text-center md:text-left ">{{'Información adicional'}}</label>
        </div>
        <div>
            <div>
                <span class="text-gray-600 font-sans text-sm font-medium tracking-wide">Fecha de Expiración</span>
                <div class="flex justify-between hover:bg-gray-100 px-2 rounded-sm">
                    <label class="text-gray-500 font-sans">NO APLICA</label>
                    <div>
                        <button>
                            <f-icon class="font-sans text-gray-400" :icon="['fa', 'clock']" />
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <span class="text-gray-600 font-sans text-sm font-medium tracking-wide">Indicadores de ventas</span>
                <v-apexchart type="bar" :options="options" :series="series"></v-apexchart>
            </div>
            <!-- <div>
                <span class="text-gray-600 font-sans text-sm font-medium tracking-wide">Citas y Reservaciones</span>
                <v-service-appointments />
                
            </div> -->
        </div>
    </v-card>
</template>

<script>
export default {
     data: function () {
        return {
            options:  { 
            chart: {
                id: 'bar-char-ventas',
                type: 'bar',
                height: 100
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -5,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
               type: 'category',
               categories: ['2022'],
               min: 0,
               max: 200.5,
            },
            annotations: {
                xaxis: [
                    {
                    x: 150,
                    borderColor: '#FE2B61',
                    label: {
                        borderColor: '#FE2B61',
                        style: {
                            color: '#fff',
                            background: '#FE2B61'
                        },
                        text: 'Meta del Mes'
                    }
                    }
                ],
            },
      
        },
         series: [{ name:'Febrero', data: [44.5] }, { name:'Marzo', data: [53.99] }],
        }
    }
}
</script>

<style>

</style>