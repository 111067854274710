import ApiProducts from "../config/ApiProducts";
import api_url from '../../utils/api.url';

export default  {
    /**
     * Funcion que permite guardar los datos de los toppings
     * @returns 
     */
  async save(data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_SAVE;
            return await ApiProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener las variantes de un producto
     * @param {*} id ID del producto/menu/servicio
     * @returns 
     */
    async getByProduct(id) {
            try {
                const url = api_url.APP_PRODUCTS_VARIANT_GET_BY_PRODUCT + id;
                return await ApiProducts.get(url)
            } catch (error) {
                return error.response.data;
            }
    },

    /**
     * Función que permite obtener los datos de la variante por ID
     * @param {*} id ID del producto/menu/servicio
     * @returns 
     */
    async getById(id) {
            try {
                const url = api_url.APP_PRODUCTS_VARIANT_GET_BY_ID + id;
                return await ApiProducts.get(url)
            } catch (error) {
                return error.response.data;
            }
    },

     /**
     * Funcion que permite actualizar los datos de la variante
     * @param {*} id ID del variante
     * @param {*} data Modelo de datos de la variante
     * @returns 
     */
      async update(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_UPDATE + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

     /**
     * Función que permite actualizar el status de la variante
     * @param {*} id ID de la variante
     * @param {*} data Modelo de datos de la variante
     * @returns 
     */
      async status(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_STATUS + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


}