export default class ToppingValue {
    constructor(
        name,
        description,
        id_topping,
        price
    ) {
        this.name = name ? name : '',
        this.description = description? description : '',
        this.id_topping = id_topping? id_topping : '',
        this.price = price? price : 0.0
    }
}