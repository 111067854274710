<template>
  <div class="grid  gap-y-3 px-0 md:px-5 py-3">
      <v-loading :is_loading="isloading" />
       <div class="block">
          <div>
            <label class="font-sans text-base text-gray-500 font-bold tracking-wide p-0 m-0">Complementos del Menú</label>
          </div>
          <div>
            <label class="font-sans text-sm text-gray-400 font-thin tracking-wide p-0 m-0">Agrega los complementos o extras de tu menú</label>
          </div>
      </div>
       <div class="w-full md:w-1/2 grid gap-y-3" >
          <button  v-if="!iscreate" @click="iscreate = true"  class="flex items-center gap-2 px-5 py-2 border-2 border-dashed border-fucsia-lighter text-fucsia-lighter font-sans font-medium justify-center">
              <f-icon :icon="['fa', 'plus']" />
              <span>AGREGAR ELEMENTO</span>
          </button>
          <!-- AGREGAR TOPPING -->
           <div v-if="iscreate" class="border border-gray-200 rounded-md px-1 py-1 grid gap-y-2 shadow-sm"  >
               <div class="grid grid-cols-1 px-1 py-1 gap-5">
                <div>
                        <label class="text-base font-sans font-semibold">Nombre</label>
                        <input :disabled="isloading" v-model="$v.topping.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                        <small class="text-error" v-show="$v.topping.name.$anyDirty && !$v.topping.name.required">* El nombre es requerido</small>
                </div>
                </div>
                <div class="flex items-center gap-5 px-1">
                    <div>
                        <label class="text-base font-sans font-semibold">Cantidad</label>
                        <input :disabled="isloading" v-model="$v.topping.quantity.$model" class="input-full-fucsia"  placeholder="Ingrese la cantidad" />
                        <small class="text-error" v-show="$v.topping.quantity.$anyDirty && !$v.topping.quantity.required">* La cantidad es requerida</small>
                    </div>
                    <div>
                        <label class="text-sm font-sans font-semibold">¿Requerido?</label>
                        <div class="w-max" >
                                <v-switch
                                color="success"
                                v-model="$v.topping.required.$model"
                                hide-details
                                :label="`${topping.required ? 'Requerido' : 'No Requerido'}`"
                                style="margin-top: 0px"
                                ></v-switch>
                        </div>
                    </div>
                </div>
                <div class="flex gap-5 items-center justify-end w-full">
                <div >
                        <button @click="iscreate = false" class="text-gray-400 text-xl hover:text-gray-500">
                            <f-icon :icon="['fa', 'times']" />
                        </button>
                </div>
                <div>
                    <button :disabled="isloading"
                      @click="save"  class=" w-full bg-fucsia-lighter text-white text-sm font-semibold tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                    >
                        Guardar
                    </button>
                </div>
            </div>
           </div>
        </div>
        <div>
        <div class="grid md:flex">
                <v-col>
                    <!-- LISTADO DE TOPPINGS -->
                <div>
                    <v-treeview
                    v-if="servicetoppings && servicetoppings.length > 0"
                    :active.sync="active"
                    :items="items"
                    :open.sync="open"
                    open-all
                    open-on-click
                    activatable
                    transition
                    expand-icon="mdi-chevron-down"
                    color="success"
                    class="text-gray-500 font-normal tracking-wide font-sans"
                    >
                    <template v-slot:prepend="{ item }">
                    <v-icon v-if="!item.children" color="teal darken-1">
                        mdi-basket
                    </v-icon>
                    </template>
                    </v-treeview>
                    <div v-if="!servicetoppings || servicetoppings.length <= 0" class="flex justify-center h-full items-center text-sm font-thin text-gray-400 text-center">
                        No cuentas con complementos disponibles
                    </div>
                </div>
            </v-col>
            <div class="hidden md:block">
              <v-divider vertical ></v-divider>
            </div>
              <div class="sm:px-0 md:px-5 py-2 sm:px-0 col">
              <div class="h-full">
                 <v-scroll-y-transition
                    mode="out-in"
                 >
                 <div v-if="!selected" class="text-sm tracking-wider font-thin text-gray-400 text-center">
                     <span>Selecciona un complemento</span>
                 </div>
                 <div v-else :key="selected.id" >
                      <div class="block gap-y-2 justify-center">
                          <div class="grid gap-y-2">
                              <!-- DATOS GENERALES DEL TOPPING -->
                            <div class="grid relative" v-if="!isupdate">
                                <div class="absolute right-0 flex gap-2">
                                     <button @click="onStatusTopping(!selected.active, selected.id)" class="bg-gray-200 px-2 py-1 rounded-sm shadow-md text-gray-500">
                                         <f-icon  v-if="!selected.active" :icon="['fa', 'toggle-off']" />
                                         <f-icon  v-if="selected.active" :icon="['fa', 'toggle-on']" />
                                    </button>
                                    <button  @click="onDeleteTopping(selected.id)" class="bg-gray-200 px-2 py-1 rounded-sm shadow-md text-gray-500">
                                         <f-icon :icon="['fa', 'trash-alt']" />
                                    </button>
                                </div>
                                <div class="text-center">
                                    <v-icon color="teal darken-1">
                                    mdi-book-multiple
                                </v-icon>
                                </div>
                                <span @click="getToppingByIdProduct(selected.id, id_menu)" 
                                    class="text-center font-semibold lg:font-normal text-sm lg:text-lg 
                                    tracking-wide hover:underline cursor-pointer" :class="{'text-gray-500': selected.active, 'text-gray-400': !selected.active}">
                                    {{selected.name ? selected.name : ''}}
                                </span>
                                <div class="flex flex-wrap gap-5 justify-center text-sm">
                                    <span class="text-gray-400 tracking-wide font-sans">Folio: {{selected.invoice ? selected.invoice : ''}}</span>
                                    <span class="text-gray-400 tracking-wide font-sans">¿Requerido?: {{selected.required ? 'SI' : 'NO'}}</span>
                                    <span class="text-gray-400 tracking-wide font-sans">Cantidad: {{selected.quantity ? selected.quantity : '0'}}</span>
                                </div>
                            </div>
                            <!-- ACTUALIZAR TOPPING -->
                             <div v-if="isupdate" class="border border-gray-200 rounded-md px-0 py-1 grid gap-y-2 shadow-sm"  >
                               
                                <div class="grid grid-cols-1 px-1 py-1 gap-5">
                                    <div>
                                            <label class="text-base font-sans font-semibold">Nombre</label>
                                            <input :disabled="isloading" v-model="$v.toppingselected.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                                            <small class="text-error" v-show="$v.toppingselected.name.$anyDirty && !$v.toppingselected.name.required">* El nombre es requerido</small>
                                    </div>
                                    </div>
                                    <div class="flex items-center gap-5 px-1">
                                        <div>
                                            <label class="text-base font-sans font-semibold">Cantidad</label>
                                            <input :disabled="isloading" v-model="$v.toppingselected.quantity.$model" class="input-full-fucsia"  placeholder="Ingrese la cantidad" />
                                            <small class="text-error" v-show="$v.toppingselected.quantity.$anyDirty && !$v.toppingselected.quantity.required">* La cantidad es requerida</small>
                                        </div>
                                        <div>
                                            <label class="text-sm font-sans font-semibold">¿Requerido?</label>
                                            <div class="w-max" >
                                                    <v-switch
                                                    color="success"
                                                    v-model="$v.toppingselected.required.$model"
                                                    hide-details
                                                    :label="`${toppingselected.required ? 'Requerido' : 'No Requerido'}`"
                                                    style="margin-top: 0px"
                                                    ></v-switch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex gap-5 px-2 items-center justify-end w-full">
                                    <div >
                                        <button @click="isupdate = false" class="text-gray-400 text-xl hover:text-gray-500">
                                            <f-icon :icon="['fa', 'times']" />
                                        </button>
                                    </div>
                                    <div>
                                        <button :disabled="isloading"
                                        @click="update(selected.id)"  class=" w-full bg-fucsia-lighter text-white text-sm font-semibold tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                          <v-divider></v-divider>
                          <div class="grid gap-y-2">
                              <!-- LISTA DE VALORES DEL TOPPING -->
                              <div v-for="(value, x) in servicetoppingvalues" :key="x" @mouseenter="onMouseEnter(x)" @mouseleave="onMouseleave">
                                  <div class="flex items-center gap-2 justify-between bg-gray-100 px-5 py-1 rounded-sm hover:bg-gray-200" :class="{'text-gray-400': !value.active}" >
                                      <div class="flex items-center gap-2 cursor-pointer">
                                          <div>
                                            <v-checkbox
                                                color="success"
                                                v-model="value.active"
                                                hide-details
                                                @click="onStatusValue(value)"
                                            ></v-checkbox>
                                          </div>
                                        <div class="w-full" @click="onSelectValue(value.id)">
                                            <span class="font-sans text-sm tracking-wide font-semibold">{{value.name ? value.name : ''}}</span>
                                        </div>
                                      </div>
                                      <div v-if="value.price && value.price > 0 && !ishover(x)">
                                            <span class="font-sans text-sm tracking-wide font-semibold">{{value.price | currency }}</span>
                                      </div>
                                      <div v-if="ishover(x)">
                                        <button @click="onDeleteValue(value.id)" class="text-gray-400 text-sm hover:text-gray-500">
                                                <f-icon :icon="['fa', 'trash-alt']" />
                                        </button>
                                      </div>
                                  </div>
                              </div>
                              <!-- AGREGAR VALOR DE TOPPING -->
                              <div>
                                <button v-if="!iscreatevalue && !isupdatevalue" @click="iscreatevalue = true" class="text-gray-500 font-sans tracking-wider bg-gray-100 px-5 py-1 rounded-sm shadow-sm hover:bg-gray-200">Nuevo Elemento</button>
                              </div>
                              <!-- ALTA DE NUEVO VALOR DE TOPPING -->
                              <div v-if="iscreatevalue" class="border border-gray-200 rounded-md px-1 py-1 grid gap-y-1 shadow-sm">
                                    <div class="grid grid-cols-1 px-1 py-1 gap-5">
                                        <div>
                                            <label class="text-base font-sans font-semibold">Nombre</label>
                                            <input :disabled="isloading" v-model="$v.toppingvalue.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                                            <small class="text-error" v-show="$v.toppingvalue.name.$anyDirty && !$v.toppingvalue.name.required">* El nombre es requerido</small>
                                        </div>
                                    </div>
                                    <div class="flex grid-cols-1 px-1 py-1 gap-5 items-center">
                                        <div>
                                            <div v-if="ispricevalue" class="flex gap-2 items-center">
                                                <div>
                                                    <input :disabled="isloading" v-model="$v.toppingvalue.price.$model" class="input-full-fucsia py-1"  placeholder="Ingrese el precio" />
                                                    <small class="text-error" v-show="$v.toppingvalue.price.$anyDirty && !$v.toppingvalue.price.required">* El precio es requerido</small>
                                                </div>
                                                <div class="flex gap-2 items-center">
                                                    <button @click="oncancelPrice" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'times']" />
                                                    </button>
                                                    <button @click="ispricevalue = false" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'check']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span v-if="!ispricevalue && !toppingvalue.price" @click="ispricevalue = true" class="text-sm tracking-wide font-sans font-semibold underline cursor-pointer">$Precio</span>
                                            <span v-if="!ispricevalue && toppingvalue.price" @click="ispricevalue = true" class="text-sm tracking-wide font-sans font-semibold underline cursor-pointer">{{toppingvalue.price | currency}}</span>
                                             
                                        </div>
                                        <div class="flex items-center justify-end gap-5">
                                            <div  >
                                                <button @click="iscreatevalue = false" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'times']" />
                                                </button>
                                            </div>
                                            <div>
                                                <button :disabled="isloading"
                                                 @click="saveToppingValue(selected.id)"   class="flex items-center justify-center gap-2 w-full bg-fucsia-lighter text-white text-sm font-semibold tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                                                >
                                                <f-icon v-if="isloading" class="animate-spin" :icon="['fa', 'spinner']" />
                                                <span>Guardar</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                              </div>
                               <!-- ACTUALIZAR EL VALOR DE UN TOPPING -->
                               <div v-if="isupdatevalue" class="border border-gray-200 rounded-md px-1 py-1 grid gap-y-1 shadow-sm">
                                    <div class="grid grid-cols-1 px-1 py-1 gap-5">
                                        <div>
                                            <label class="text-base font-sans font-semibold">Nombre</label>
                                            <input :disabled="isloading" v-model="$v.selectedToppingvalue.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                                            <small class="text-error" v-show="$v.selectedToppingvalue.name.$anyDirty && !$v.selectedToppingvalue.name.required">* El nombre es requerido</small>
                                        </div>
                                    </div>
                                    <div class="flex grid-cols-1 px-1 py-1 gap-5 items-center">
                                        <div>
                                            <div v-if="ispriceupdatevalue" class="flex gap-2 items-center">
                                                <div>
                                                    <input :disabled="isloading" v-model="$v.selectedToppingvalue.price.$model" class="input-full-fucsia py-1"  placeholder="Ingrese el precio" />
                                                    <small class="text-error" v-show="$v.selectedToppingvalue.price.$anyDirty && !$v.selectedToppingvalue.price.required">* El precio es requerido</small>
                                                </div>
                                                <div class="flex gap-2 items-center">
                                                    <button @click="oncancelPriceUpdate" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'times']" />
                                                    </button>
                                                    <button @click="ispriceupdatevalue = false" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'check']" />
                                                    </button>
                                                </div>
                                            </div>
                                            <span v-if="!ispriceupdatevalue && !selectedToppingvalue.price" @click="ispriceupdatevalue = true" class="text-sm tracking-wide font-sans font-semibold underline cursor-pointer">$Precio</span>
                                            <span v-if="!ispriceupdatevalue && selectedToppingvalue.price" @click="ispriceupdatevalue = true" class="text-sm tracking-wide font-sans font-semibold underline cursor-pointer">{{selectedToppingvalue.price | currency}}</span>
                                        </div>
                                        <div class="flex items-center justify-end gap-5">
                                            <div  >
                                                <button @click="isupdatevalue = false" class="text-gray-400 text-xl hover:text-gray-500">
                                                        <f-icon :icon="['fa', 'times']" />
                                                </button>
                                            </div>
                                            <div>
                                                <button :disabled="isloading"
                                                  @click="updateToppingValue(selectedToppingvalue.id, selectedToppingvalue.id_topping)"  class="flex items-center justify-center gap-2 w-full bg-fucsia-lighter text-white text-sm font-semibold tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                                                >
                                                <f-icon v-if="isloading" class="animate-spin" :icon="['fa', 'spinner']" />
                                                <span>Guardar</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                              </div>
                          </div>
                        </div>
                        
                      </div>
                 </div>
                 </v-scroll-y-transition>
              </div>
               
               </div>
               
            </div>
               
            </div>
  </div>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import globalMixin from "@/mixins/globalMixin";
import { validationMixin } from 'vuelidate';
import {  requiredIf } from 'vuelidate/lib/validators';
import { ToppingService, ToppingValueService } from '../../../../../services/HSuite/Context/hsuite-product-context';
import Topping from '../../../../../models/topping/topping.model';
import ToppingValue from '../../../../../models/topping/toppingvalue.model';
import productContext from '../../../../../utils/common/productcontext';

export default {
    mixins: [toastMixin, globalMixin, validationMixin],
    props: {
        id_menu: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            iscreate: false,
            iscreatevalue: false,
            isupdate: false,
            ispricevalue: false,
            ispriceupdatevalue: false,
            isupdatevalue: false,
            ismouseenter: false,
            indexhover: 0,
            topping: new Topping(),
            toppingselected: new Topping(),
            toppingvalue: new ToppingValue(),
            selectedToppingvalue: new ToppingValue(),
            servicetoppings: [],
            servicetoppingvalues: [],
            active: [],
            open: [],
        }
    },
    validations: {
         topping: {
            name: {
                required: requiredIf(function() {
                    return this.iscreate ? true : false;
                })
            },
            code_type_product: {
                required: requiredIf(function() {
                    return this.iscreate ? true : false;
                })
            },
            id_product: {
                required: requiredIf(function() {
                    return this.iscreate ? true : false;
                })
            },
            required: {
                required: requiredIf(function() {
                    return this.iscreate ? false : false;
                })
            },
            quantity: {
                required: requiredIf(function() {
                    return this.iscreate ? false : false;
                })
            }
        },
         toppingselected: {
            name: {
                required: requiredIf(function() {
                    return this.isupdate ? true : false;
                })
            },
            code_type_product: {
                required: requiredIf(function() {
                    return this.isupdate ? true : false;
                })
            },
            id_product: {
                required: requiredIf(function() {
                    return this.isupdate ? true : false;
                })
            },
            required: {
                required: requiredIf(function() {
                    return this.isupdate ? false : false;
                })
            },
            quantity: {
                required: requiredIf(function() {
                    return this.isupdate ? false : false;
                })
            }
        },
        toppingvalue: {
            name: {
                required: requiredIf(function() {
                    return this.iscreatevalue ? true : false;
                })
            },
            id_topping: {
                required:  requiredIf(function() {
                    return this.iscreatevalue ? true : false;
                })
            },
            price: {
                 required:  requiredIf(function() {
                    return this.iscreatevalue ? false : false;
                })
            }
        },
        selectedToppingvalue: {
            name: {
                required: requiredIf(function() {
                    return this.isupdatevalue ? true : false;
                })
            },
            id_topping: {
                required:  requiredIf(function() {
                    return this.isupdatevalue ? true : false;
                })
            },
            price: {
                 required:  requiredIf(function() {
                    return this.isupdatevalue ? false : false;
                })
            }
        }

    },
     mounted() {
        this.init();
    },
    methods: {
        init: async function() {
             this.isloading = true;
             await this.getToppings(this.id_menu);
             this.isloading = false;
        },
        getToppings: async function(id) {
            let response = await ToppingService.getByProduct(id);
            if(response.success) {
                this.servicetoppings = response.data;
            }else {
                this.failure(response.message);
            }
        },
        getToppingValues: async function(id) {
            let response = await ToppingValueService.getByTopping(id);
            if(response.success) {
                this.servicetoppingvalues = response.data;
            }else {
                this.failure(response.message);
            }
        },
        getToppingByIdProduct: async function(id, id_product) {
            this.isupdate = true;
            let response = await ToppingService.getByIdProduct(id, id_product);
            if(response.success) {
                this.toppingselected = response.data;
            }else {
                this.failure(response.message);
            }
        },
        save: async function() {
            this.topping.code_type_product = productContext.SYST_MENU;
            this.topping.id_product = this.id_menu;
            this.$v.$touch();
            if(!this.$v.$anyError) {
                
                this.isloading = true;
                let response = await ToppingService.save(this.topping);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                    this.iscreate = false;

                }else {
                    this.failure(response.message);
                }
            }
        },
        update: async function(id) {
          
            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await ToppingService.update(id, this.toppingselected);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                    this.isupdate = false;

                }else {
                    this.failure(response.message);
                }
            }
        },
        saveToppingValue: async function(id_topping) {
            this.toppingvalue.id_topping = id_topping;
            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await ToppingValueService.save(this.toppingvalue);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.iscreatevalue = false;
                    this.getToppingValues(id_topping);
                }else {
                    this.iscreatevalue = false;
                    this.failure(response.message);
                }
            }
        },
        updateToppingValue: async function(id, id_topping) {

            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await ToppingValueService.update(id, this.selectedToppingvalue);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.isupdatevalue = false;
                    this.getToppingValues(id_topping);
                }else {
                    this.isupdatevalue = false;
                    this.failure(response.message);
                }
            }
        },

          onStatusValue: async function (value) {
            const confirm = await this.confirm(`${value.active ? 'Mostrar' : 'Ocultar'}`, `¿Deseas ${value.active ? 'mostrar' : 'ocultar'} el elemento seleccionado?`);
            if(confirm) {
                const data = {
                    active: value.active,
                    type: 1
                };
                this.isloading = true;
                let response = await ToppingValueService.status(value.id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                    value.active = !value.active;
                }
                
            }else {
                value.active = !value.active;
            }
        },
        onDeleteValue: async function(id) {
            const confirm = await this.confirm(`Eliminar Valor`, `¿Deseas eliminar el valor seleccionado?`);
            if(confirm) {
                  const data = {
                    active: false,
                    type: 2
                };
                this.isloading = true;
                let response = await ToppingValueService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                }

            }
        },
        onDeleteTopping: async function(id) {
            const confirm = await this.confirm(`Eliminar Complemento`, `¿Deseas eliminar el complemento seleccionado?`);
            if(confirm) {
                  const data = {
                    active: false,
                    type: 2
                };
                this.isloading = true;
                let response = await ToppingService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                }

            }
        },
        onStatusTopping: async function(value, id) {
            const confirm = await this.confirm(`${value ? 'Mostrar' : 'Ocultar'}`, `¿Deseas ${value ? 'mostrar' : 'ocultar'} el elemento seleccionado?`);
            if(confirm) {
                const data = {
                    active: value,
                    type: 1
                };
                this.isloading = true;
                let response = await ToppingService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.init();
                }else {
                    this.failure(response.message);
                }
                
            }
        },
        oncancelPrice: function() {
            this.ispricevalue = false;
            this.toppingvalue.price = 0;
        },
        oncancelPriceUpdate: function() {
            this.ispriceupdatevalue = false;
            this.selectedToppingvalue.price = 0;
        },
        onSelectValue: async function(id){
            this.isupdatevalue = true;
            let response = await ToppingValueService.getById(id);
            if(response.success) {
                this.selectedToppingvalue = response.data;
            }else {
                this.failure(response.message);
            }
        },
        onMouseEnter: function(index) {
            this.ismouseenter = true;
            this.indexhover = index;
        },
        onMouseleave: function() {
            this.ismouseenter = false;
            this.indexhover = 0;
        },
        ishover: function(index) {
            return this.indexhover == index && this.ismouseenter ? true : false;
        }


    },
    computed: {
      items () {
        return [
          {
            name: 'Complementos',
            children: this.servicetoppings,
          },
        ]
      },
      selected () {
        if (!this.active.length) return undefined
        const id = this.active[0]

        return this.servicetoppings.find(tp => tp.id === id)
      },
     },
    watch: {
        iscreate: function() {
            if(this.iscreate) {
                this.iscreatevalue = false;
                this.topping = new Topping();
                this.$v.$reset();
            }
        },
        isupdate: function() {
            if(this.isupdate) {
                this.iscreatevalue = false;
                this.iscreate = false;
                this.isupdatevalue = false;
                this.toppingselected = new Topping();
                this.$v.$reset();
            }
        },
        iscreatevalue: function() {
            if(this.iscreatevalue) {
                this.iscreate = false;
                this.isupdate = false;
                this.isupdatevalue = false;
                this.ispricevalue = false;
                this.toppingvalue = new ToppingValue();
                this.$v.$reset();
            }
        },
        isupdatevalue: function() {
            if(this.isupdatevalue) {
                this.iscreate = false;
                this.isupdate = false;
                this.iscreatevalue = false;
                this.ispriceupdatevalue = false;
                this.selectedToppingvalue = new ToppingValue();
                this.$v.$reset();
            }
        },
        selected: function() {
            if(this.selected && this.selected.id) {
                this.servicetoppingvalues = [];
                this.getToppingValues(this.selected.id);
            }
        }
    }

}
</script>

<style scoped>
 .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

</style>