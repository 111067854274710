import ApiProducts from "../config/ApiProducts";
import api_url from '../../utils/api.url';

export default  {
    /**
     * Funcion que permite guardar los valores de la variante
     * @returns 
     */
    async save(data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_VALUE_SAVE;
            return await ApiProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar los datos de un valor de la variante
     * @param {*} id ID del valor
     * @param {*} data Modelo de datos del valor
     * @returns 
     */
    async update(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_VALUE_UPDATE + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los valores de una variante
     * @param {*} id ID de la variante
     * @returns 
     */
    async getByVariant(id) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_VALUE_GET_BY_VARIANT + id;
            return await ApiProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los datos del valor de la variante
     * @param {*} id ID del valor
     * @returns 
     */
    async getById(id) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_VALUE_GET_BY_ID + id;
            return await ApiProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite actualizar el status del valor
     * @param {*} id ID del valor
     * @param {*} data Modelo de datos del valor
     * @returns 
     */
    async status(id, data) {
        try {
            const url = api_url.APP_PRODUCTS_VARIANT_VALUE_STATUS + id;
            return await ApiProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


}