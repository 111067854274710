export default class VariantValue {
    constructor(
        name,
        description,
        id_variant,
        price
    ) {
        this.name = name ? name : '',
        this.description = description? description : '',
        this.id_variant = id_variant? id_variant : '',
        this.price = price ? price : 0.0
    }
}