<template>
  <v-card elevation="2" class="px-5 rounded-lg">
       <div class="sm:px-0 md:px-10 grid md:flex gap-10 items-start w-full">
        <div>
            <img src="/img/svg/menu.svg" class="h-60 w-80 bg-cover">
        </div>
        <div class="sm:justify-center md:justify-start w-full sm:py-3 md:py-5 gap-y-2">
            <div class="w-full">
                <div class="flex sm:justify-center md:justify-between ">
                    <label class="font-sans text-xl md:text-3xl font-bold text-center tracking-wide text-gray-500">{{menu.name ? menu.name : ''}}</label>
                    <div class="hidden md:block">
                        <button class="bg-gray-200 px-5 py-1 rounded-md text-gray-500 shadow-sm hover:shadow-md hover:bg-gray-300">
                            <f-icon :icon="['fa', 'ellipsis-h']" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="sm:text-center md:text-left">
                <span class="font-sans text-lg text-gray-400 font-medium">Menú ID #</span>
                    <span class="font-sans text-lg text-gray-500 font-medium">{{menu.invoice ? menu.invoice : ''}}</span>
            </div>
            <div>
                <div class="flex gap-2 items-center text-gray-600 font-sans font-medium tracking-wide">
                    <f-icon :icon="['fa', 'th-list']" />
                    <span>Descripción</span>
                </div>
                <div class="text-gray-700 font-sans text-sm tracking-wide bg-white border border-gray-100 px-2 py-2 rounded-md shadow-sm">
                    <p>{{menu.description ? menu.description : ''}}</p>
                </div>
            </div>
            <div class="grid md:flex justify-around items-center gap-2 gap-y-2 py-2">
                
                <div>
                    <div   class="flex items-center gap-2 px-5 py-2 bg-green-100  rounded-md shadow-sm">
                        <f-icon class="text-green-500" :icon="['fa', 'dollar-sign']" />
                        <div class="font-sans tracking-wide text-center text-green-500">
                            <div>
                                <label>{{ menu.price | currency }}</label>
                            </div>
                            <div>
                                <span class="font-bold ">IMPORTE TOTAL</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                        <div   class="flex items-center gap-2 px-5 py-2 bg-indigo-100  rounded-md shadow-sm">
                        <f-icon class="text-indigo-500" :icon="['fa', 'calendar-day']" />
                        <div class="font-sans tracking-wide text-center text-indigo-500">
                            <div>
                                <label>{{  menu.created_at | formatDate }}</label>
                            </div>
                            <div>
                                <span class="font-bold ">FECHA CREACIÓN</span>
                            </div>
                        </div>
                    </div>
                </div>
                    <div>
                        <div   class="flex items-center gap-2 px-5 py-2 bg-red-100 rounded-md shadow-sm">
                        <f-icon class="text-red-500" :icon="['fa', 'calendar-minus']" />
                        <div class="font-sans tracking-wide text-center text-red-500">
                            <div>
                                <label>{{ 'NO APLICA' }}</label>
                            </div>
                            <div>
                                <span class="font-bold ">FECHA EXPIRACIÓN</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  </v-card>
</template>

<script>
export default {
     props: {
        menu: {
            type: Object,
            default: function() {return {}}
        }
    },
}
</script>

<style>

</style>